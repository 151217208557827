/* date/time */
.wh-styledinput .datetime {
  flex: none;
  padding: 0 10px;
}
.wh-styledinput .datetime__part {
  box-shadow: none !important;
  border: 0 none;
  padding: 0;
  max-height: 42px;
}
.wh-styledinput .datetime__sep {
  padding: 0 4px;
}
.wh-styledinput .datetime__year {
  text-align: left;
}
.wh-styledinput .datetime__controls {
  margin-left: 5px;
  color: #283477;
}
.wh-styledinput .datetime__controls > span {
  margin-left: 5px;
  cursor: pointer;
  width: 20px;
  height: 20px;
  font-weight: 300;
  font-size: 20px;
  font-family: "Font Awesome 5 Pro";
}
.wh-styledinput .datetime__togglepicker {
  vertical-align: baseline;
}
.wh-styledinput .datetime__togglepicker::before {
  content: "\f073";
  font-size: 18px;
}
.wh-styledinput .datetime__reset {
  vertical-align: middle;
}
.wh-styledinput .datetime__reset::before {
  content: "\f00d";
}

.datetime__picker {
  border: 2px solid #283477;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 10px 7px rgba(0, 0, 0, 0.1);
}
.datetime__picker__previous, .datetime__picker__next {
  display: none;
}
.datetime__picker select, .datetime__picker input {
  height: 24px;
  font-size: inherit;
}
.datetime__picker input {
  padding-left: 10px;
}
.datetime__picker__weekday {
  padding: 5px 5px 3px;
}
.datetime__picker__day {
  cursor: pointer;
  outline: 0 none;
  padding: 0 5px;
}
.datetime__picker__day span {
  border-radius: 3px;
  display: block;
  padding: 5px 5px 5px 5px;
  text-align: right;
  border: 1px solid #fff;
  transition: border-color 0.2s;
}
.datetime__picker__day--selected > span {
  background-color: #283477;
  color: #fff;
}
.datetime__picker__day:hover > span, .datetime__picker__day:focus > span {
  border-color: #283477;
}
.datetime__picker__header {
  background-color: #283477;
  padding: 3px;
  display: flex;
  justify-content: space-between;
}