@import url("https://fonts.googleapis.com/css?family=Roboto:400,700");

html
{
  font: 14px/1.5em Roboto, 'Arial', sans-serif;
  color: #303030;
  background: #ffffff;
}

main a[href]
{
  color: #303030;
  text-decoration: underline;
}
main a[href]:hover
{
  text-decoration: underline;
}

h1
{
  font: 24px 'Times', sans-serif;
  font-weight: bold;
  color: #985135;
  margin: 18px 0 10px 0;
  text-align: left;
  text-transform: uppercase;
}
h2
{
  font: 20px 'Times', sans-serif;
  font-weight: bold;
  color: #985135;
  margin: 18px 0 10px 0;
  font-weight: bold;
  text-transform: uppercase;
}
h3
{
  color: #985135;
  font-size: 110%;
  margin: 16px 0 0 0;
}
p
{
  margin: 0;
  line-height: 1.7em;
}
p.centered
{
  text-align: center;
  color: #303030;
  margin: 0;
}
li
{
  line-height: 1.7em;
}
