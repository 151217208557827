@import '~dompack/browserfix/reset.css';
@import './richcontent.css';
.wh-form__fieldgroup:not(.wh-form__fieldgroup--hidden) {
  display: flex;
}

.wh-form__fieldgroup {
  align-items: baseline;
}

.wh-form__label, .wh-form__optiondata,
.wh-form__optionlabel,
.wh-form__subfieldlabel {
  display: inline-block;
}

.wh-form__label {
  flex-shrink: 0;
}

.wh-form__fields {
  flex: 1 0 0px;
}

.wh-form__fieldline {
  width: 100%;
  align-items: baseline;
  flex-wrap: wrap;
}

.wh-form__fieldline:not(.wh-form__fieldline--hidden) {
  display: inline-flex;
}

.wh-form__fieldline--nowrap:not(.wh-form__fieldline--hasinfotext) {
  flex-wrap: nowrap;
}

.wh-form__fieldgroup--horizontal .wh-form__fields {
  display: flex;
  flex-wrap: wrap;
}
.wh-form__fieldgroup--horizontal .wh-form__fieldline {
  flex: none;
  width: auto;
  max-width: 100%;
}

.wh-form__infotextwrapper {
  flex: 0 0 100%;
}

.wh-form__optiondata {
  display: inline-flex;
  width: 100%;
  align-items: baseline;
  flex-grow: 1;
  flex-wrap: wrap;
}
.wh-form__optiondata--vertical {
  width: 0;
}

.wh-form__subfield {
  display: flex;
  flex-grow: 1;
  align-items: baseline;
}

.wh-form__fieldline > input[type=checkbox],
.wh-form__fieldline > input[type=radio] {
  flex-shrink: 0;
}

.wh-form__fieldline > textarea {
  resize: vertical;
}

.wh-form__label {
  min-width: 150px;
}

.wh-form__optionlabel {
  margin-left: 8px;
}

.wh-form__fieldgroup {
  margin-bottom: 8px;
}

.wh-form__button {
  background-color: #84755b;
  color: #ffffff;
  width: 140px;
  margin-top: 20px;
}

@media (max-width: 750px) {
  /* Order the inputs vertical from their labels */
  .wh-form__fieldgroup {
    -ms-flex-direction: column;
    -webkit-flex-direction: column;
    flex-direction: column;
  }
  /* Give the fields their full width back */
  .wh-form__fields {
    width: 100%;
  }
}
* {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
}

.pagemain {
  margin: 65px auto 30px;
  padding: 0 10px;
  flex: 1 0 auto;
  width: 960px;
  max-width: 100%;
  z-index: 0;
}

.header {
  position: relative;
  z-index: 1;
}

.header__menuholder {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  padding: 15px 0 0 0;
  height: 50px;
  background: #84755b;
  text-align: center;
  color: #ffffff;
  box-shadow: 0px 0px 18px rgba(132, 117, 91, 0.4);
}

.header__topmenulogo, .header__topmenuitem {
  vertical-align: middle;
  display: inline-block;
}

.header__topmenuitem {
  margin: 0 7px;
}

.logoholder {
  display: block;
  text-align: center;
}

.logoholder__logo {
  max-width: calc(100% - 40px);
}

.pure-menu-link:visited {
  color: #ffffff;
}

.pure-menu-link {
  font-weight: 700;
  font-size: 13px;
  letter-spacing: 3px;
  display: inline-block;
}

.pure-menu-children {
  padding-top: 13px;
  background-color: transparent;
}

.pure-menu-children li {
  background-color: #84755b;
  text-align: left;
  padding: 5px 65px 5px 2px;
}

.pure-menu-children li a {
  font-size: 12px;
  letter-spacing: 1px;
}

.header__firstlevellink,
.header__firstlevellink:visited {
  color: #ffffff !important;
  text-transform: uppercase;
  padding: 0 15px;
  width: 100%;
}

.header__firstlevellink:hover {
  background-color: inherit;
  color: #c9c9c9 !important;
}

.header__secondlevellink,
.header__secondlevellink:visited {
  width: 100%;
  color: #ffffff !important;
}

.header__secondlevellink:hover {
  background-color: inherit;
  color: #c9c9c9 !important;
}

.wh-rtd__tablecell {
  padding-right: 15px;
}

.mobileheader {
  position: fixed;
  left: 0;
  right: 0;
  height: 50px;
  background: #84755b;
  z-index: 1;
}

.mobileheader__homebutton {
  display: block;
  position: absolute;
  width: 100px;
  height: 36.5px;
  left: 10px;
  top: 6.75px;
  background: url("../web/img/logopersephone-wit.png") 50% 50%/100px 36.5px no-repeat;
}

.mobileheader__menubutton {
  position: absolute;
  right: 0;
  top: 0px;
  bottom: 0;
  width: 60px;
  background: url("../web/img/hamburger.png") 50% 50%/50% 50% no-repeat;
}

.mobileheader__menu {
  position: fixed;
  overflow: auto;
  top: 50px;
  height: 0;
  left: 0;
  right: 0;
  background: rgba(132, 117, 91, 0.95);
  transition: height 300ms;
  max-height: 100%;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.45);
}

.mobileheader__topmenu,
.mobileheader__submenu {
  list-style: none;
}

.mobileheader__firstlevellink,
.mobileheader__secondlevellink {
  color: #ffffff;
  text-decoration: none;
}

@media (max-width: 750px) {
  html.mobile--menuopen main {
    overflow: hidden;
  }
  .header,
  .logoholder__logo {
    display: none;
  }
  .pagemain {
    margin-top: 60px;
  }
  .wh-rtd__table.table {
    display: block;
  }
  .wh-rtd__table.table .wh-rtd__tablecell {
    display: block;
  }
}
@media (min-width: 749px) {
  .mobileheader {
    display: none;
  }
}
.footer {
  background: #84755b;
  padding: 16px 0;
}

.footer__centered {
  max-width: 960px;
  padding: 0 20px;
  margin: 0 auto;
  color: #ffffff;
  font-size: 13px;
  line-height: 1.8em;
  display: flex;
  align-items: center;
}

.footer__credits {
  flex: 1 0 auto;
  padding: 0 30px;
  text-align: center;
}

@media (max-width: 750px) {
  .footer__centered {
    flex-wrap: wrap;
  }
  .footer__credits {
    order: -1;
    flex: 1 0 100%;
  }
  .footer__logoleft,
  .footer__logoright {
    flex: 1 0 50%;
    text-align: center;
  }
}
@media (max-width: 750px) {
  .wh-rtd__img--floatleft,
  .wh-rtd__img--floatright {
    max-width: 150px;
    height: auto;
  }
}